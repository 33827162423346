export const getOSInfo = () => {
  const userAgent = window.navigator.userAgent;
  let os = "Unknown OS";

  if (userAgent.indexOf("Win") !== -1) os = "Windows";
  if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
  if (userAgent.indexOf("Linux") !== -1) os = "Linux";
  if (userAgent.indexOf("Android") !== -1) os = "Android";
  if (userAgent.indexOf("like Mac") !== -1) os = "iOS";
  return os;
};

export const getUserLocation = async () => {
  const response = await fetch("https://ipapi.co/json/");
  const data = await response.json();
  return {
    country: data.country_name,
    city: data.city,
  };
};
