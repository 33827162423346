import React from "react";
import HeaderPage from "./HeaderPage";
import FooterPages from "./FooterPages";

function Layout(props) {
  const lang = localStorage.getItem("Lang");
  return (
    <div
      className={`max-w-7xl mx-auto ${
        lang === "ar" ? "font-almarai" : "font-Maven_Pro"
      }`}
    >
      <title>{props.title}</title>
      <HeaderPage />
      {props.children}
      <FooterPages />
    </div>
  );
}

export default Layout;
