import { useNavigate, useParams, Link } from "react-router-dom";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import Spacer from "./Spacer";
import Visibility from "./utils/Visibility";
import { indexStore } from "../store/indexStore";
import { useEffect, useState } from "react";
import LoadingComponent from "./LoadingComponent/LoadingComponent";
import { LuGlobe } from "react-icons/lu";

export const Header = (props) => {
  const [scroll, setScroll] = useState(false);
  const { name, restaurant, loading, lang, changeLangBottomSheet } =
    indexStore();

  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    window.addEventListener("scroll", () => {});
    const handleScroll = () => {
      setScroll(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`flex justify-between items-center w-full p-4 bg-background shadow-sm z-10 sticky top-0 ${
        scroll ? "scroll" : ""
      } ${props.backgroundColorfull ? "bg-primary" : " "}`}
    >
      <Visibility visible={!props.noBack}>
        <button
          className={` text-lg cursor-pointer text-onBackground ${
            props.backgroundColorfull ? "bg-primary text-onPrimary" : " "
          }`}
          onClick={() => {
            navigate(props.url || -1);
          }}
        >
          {localStorage.getItem("Lang") === "ar" ? (
            <FaAngleRight />
          ) : (
            <FaAngleLeft />
          )}
        </button>
      </Visibility>
      <Visibility visible={props.noBack}>
        <Spacer width={30} />
      </Visibility>
      <Link to={`/${params.domain}/${params.branch_id}`}>
        <Visibility visible={!loading}>
          <div
            className={`text-primary font-bold text-xl uppercase tracking-widest text-center ${
              props.backgroundColorfull ? "!text-onPrimary" : ""
            }`}
          >
            {restaurant[name]}
          </div>
        </Visibility>
        <Visibility visible={loading}>
          <LoadingComponent width={100} height={20} count={[1]} />
        </Visibility>
      </Link>
      <div
        className={`text-onBackground flex gap-1 justify-between items-center p-2 cursor-pointer ${
          props.backgroundColorfull ? "bg-primary text-onPrimary" : " "
        }`}
        onClick={() => changeLangBottomSheet(true)}
      >
        {lang === "ar" ? "ع" : "EN"}
        <LuGlobe />
      </div>
    </div>
  );
};
